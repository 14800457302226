import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';

import { Feature } from 'ol';
import MapContext from './MapContext';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import speed from '../images/speed.svg';
import parking from '../images/parking.svg';
import { withWidth } from '@material-ui/core';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { formatDate, formatHours } from '../common/formatter';

const StopsMarker = ({ stops, stopsMarkers }) => {
  const { stopsMarkerSource } = useContext(MapContext);

  const createFeature = (label, position, type) => {
    var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));
    var iconStyle = new Style({
      image: new Icon({
        src: (type == 'speed') ? speed : parking,
        scale: 1,
        anchor: [0.5, 1],
      }),
      text: new Text({
        text: label ? label : '',
        scale: 1.5,
        offsetY: -45,
        overflow: true,
        backgroundFill: new Fill({
          color: [200, 200, 200, 0.6],
        })
      })
    });
    marker.setStyle(iconStyle);

    return marker
  };

  useEffect(() => {
    stopsMarkerSource.clear();
    if (stopsMarkers && stops && stops.length > 0) {
      stops.map(position => {
        stopsMarkerSource.addFeatures([createFeature(`${formatDate(position.startTime)} - ${formatDate(position.endTime)} (${formatHours(position.duration)})`, position, 'stops')]);
      });
    }
  }, [stops, stopsMarkers]);

  return null;

}

export default withWidth()(StopsMarker);
