import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';

import { Feature } from 'ol';
import MapContext from './MapContext';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import speed from '../images/speed.svg';
import parking from '../images/parking.svg';
import { withWidth } from '@material-ui/core';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { formatSpeed } from '../common/formatter';

const SpeedMarker = ({ route, speedMarkers }) => {
  const { speedMarkerSource } = useContext(MapContext);

  const createFeature = (label, position, type) => {
    var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));
    var iconStyle = new Style({
      image: new Icon({
        src: (type == 'speed') ? speed : parking,
        scale: 1,
        anchor: [0.5, 1],
      }),
      text: new Text({
        text: label ? label : '',
        scale: 1.5,
        offsetY: -45,
        overflow: true,
        backgroundFill: new Fill({
          color: [200, 200, 200, 0.6],
        })
      })
    });
    marker.setStyle(iconStyle);

    return marker
  };

  const isAddSpeedMarkerPrev = (prev, cur) => {
    return (
      prev <= 90 && cur > 90
    )
  }

  const isAddSpeedMarkerCur = (prev, cur) => {
    return (
      cur <= 90 && prev > 90
    )
  }

  useEffect(() => {
    speedMarkerSource.clear();
    if (speedMarkers && route && route.length > 0) {
      let prev = null;

      route.map(position => {
        if (!!prev) {
          if (isAddSpeedMarkerPrev(prev.speed * 1.852, position.speed * 1.852))
            speedMarkerSource.addFeatures([createFeature(formatSpeed(position.speed, 'kmh'), prev, 'speed')]);
          if (isAddSpeedMarkerCur(prev.speed * 1.852, position.speed * 1.852))
            speedMarkerSource.addFeatures([createFeature(formatSpeed(position.speed, 'kmh'), position, 'speed')]);

          prev = position;
        } else {
          prev = position;
        }
      });

    }
  }, [route, speedMarkers]);

  return null;

}

export default withWidth()(SpeedMarker);
