import React, { useState } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@material-ui/core';
import t from '../../common/localization';
import { formatHours, formatDate } from '../../common/formatter';
import { useAttributePreference } from '../../common/preferences';

const Stops = (props) => {

  const { onClick, items } = props;
  const [selectedRow, setSelectedRow] = useState(-1);

  return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('reportStartTime')}</TableCell>
              <TableCell>{t('reportEndTime')}</TableCell>
              <TableCell>{t('reportDuration')}</TableCell>
              <TableCell>Адрес</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items && items.map((item, index) => (
              <TableRow key={index} onClick={() => { onClick(item); setSelectedRow(index) }} style={{ backgroundColor: (index === selectedRow) ? "lightgray" : "" }}>
                <TableCell>{formatDate(item.startTime)}</TableCell>
                <TableCell>{formatDate(item.endTime)}</TableCell>
                <TableCell>{formatHours(item.duration)}</TableCell>
                <TableCell>{item.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default Stops;
