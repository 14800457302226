import React from 'react';
import { formatDate, formatPosition, formatSpeed } from '../common/formatter';
import { Grid, isWidthDown, makeStyles, SvgIcon, withWidth } from '@material-ui/core';

import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import SpeedIcon from '@material-ui/icons/Speed';
import { ReactComponent as SatIcon } from '../images/sat.svg';
import { ReactComponent as DateIcon } from '../images/date-icon.svg';
import { ReactComponent as MapIcon } from '../images/map-address-icon.svg';

const useStyles = makeStyles(theme => ({
  tableCell: {
    color: 'white',
    fontSize: '1em'
  },
}));

const PathMarkerView = ({ item, width }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} style={{ maxWidth: (isWidthDown('xs', width)) ? "85vw" : "35vw" }}>
      <Grid item xs={6} className={classes.tableCell}>
        <Grid container alignItems="center" justify="space-around">
          <Grid item>
            <SvgIcon>
              <DateIcon />
            </SvgIcon>
          </Grid>
          <Grid item>
            {formatDate(item.deviceTime)}
          </Grid>
        </Grid>
      </Grid>

      {item.speed &&
        <Grid item xs={6} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item>
              <SpeedIcon />
            </Grid>
            <Grid item>
              {formatSpeed(item.speed, 'kmh')}
            </Grid>
          </Grid>
        </Grid>
      }

      {item.address &&
        <Grid item xs={12} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around" direction="row">
            <Grid item>
              <SvgIcon>
                <MapIcon />
              </SvgIcon>
            </Grid>
            <Grid item>
              {item.address}
            </Grid>
          </Grid>
        </Grid>
      }

      {!!item.attributes.rssi &&
        <Grid item xs={3} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item>
              <SignalCellularAltIcon />
            </Grid>
            <Grid item>
              {item.attributes.rssi}%
            </Grid>
          </Grid>
        </Grid>
      }
      {!!item.attributes.sat &&
        <Grid item xs={3} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item>
              <SvgIcon>
                <SatIcon />
              </SvgIcon>
            </Grid>
            <Grid item>
              {item.attributes.sat}
            </Grid>
          </Grid>
        </Grid>
      }
      {!!item.attributes.power &&
        <Grid item xs={3} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item>
              <SettingsPowerIcon />
            </Grid>
            <Grid item>
              {formatPosition(item.attributes.power, 'speed')} v
                </Grid>
          </Grid>
        </Grid>
      }
      {!!item.attributes.battery &&
        <Grid item xs={3} className={classes.tableCell}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item>
              <BatteryChargingFullIcon />
            </Grid>
            <Grid item>
              {formatPosition(item.attributes.battery, 'batteryLevel')}
                </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

export default withWidth()(PathMarkerView);
