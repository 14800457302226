import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import proj4 from 'proj4'
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { fromLonLat } from 'ol/proj';
import arrowOnIcon from '../images/arrow-on.svg'
import arrowOffIcon from '../images/arrow-off.svg'
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import MapContext from './MapContext';

const PositionsMap = ({ positions }) => {
  const devices = useSelector(state => state.devices.items);
  const { markerSource } = useContext(MapContext);

  const createFeature = (devices, position) => {
    const device = devices[position.deviceId] || null;
    var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));
    var iconStyle = new Style({
      image: new Icon({
        src: (device && device.status == 'online') ? arrowOnIcon : arrowOffIcon,
        rotation: position.course * 0.017,
        scale: 2.1
      }),
      text: new Text({
        text: device ? device.name : '',
        offsetY: -30,
        scale: 1.5,
        overflow: true,
        backgroundFill: new Fill({
          color: [200, 200, 200, 0.6],
        })
      })
    });
    marker.setStyle(iconStyle);
    marker.set('device', device);

    return marker
  };


  useEffect(() => {
    markerSource.clear();
    var MarkerFeatures = []
    proj4.defs([
      [
        "EPSG:3857",
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"],
      [
        "EPSG:3395",
        "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"],
      [
        "EPSG:4326",
        "+proj=longlat +datum=WGS84 +no_defs"]
    ]);

    positions.map(position => {
      MarkerFeatures.push(createFeature(devices, position))
    });
    markerSource.addFeatures(MarkerFeatures);

  }, [devices, positions]);



  return (<></>);

















  /*const createFeature = (devices, position) => {
    const device = devices[position.deviceId] || null;
    return {
      deviceId: position.deviceId,
      name: device ? device.name : '',
      category: device && (device.category || 'default'),
    }
  };

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onClickCallback = useCallback(event => {
    const feature = event.features[0];
    let coordinates = feature.geometry.coordinates.slice();
    while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const placeholder = document.createElement('div');
    ReactDOM.render(
      <Provider store={store}>
        <StatusView deviceId={feature.properties.deviceId} onShowDetails={positionId => history.push(`/position/${positionId}`)} />
      </Provider>,
      placeholder
    );

    new mapboxgl.Popup({
      offset: 25,
      anchor: 'top'
    })
      .setDOMContent(placeholder)
      .setLngLat(coordinates)
      .addTo(map);
  }, [history]);

  useEffect(() => {
    map.addSource(id, {
      'type': 'geojson',
      'data': {
        type: 'FeatureCollection',
        features: [],
      }
    });
    map.addLayer({
      'id': id,
      'type': 'symbol',
      'source': id,
      'layout': {
        'icon-image': '{category}',
        'icon-allow-overlap': true,
        'text-field': '{name}',
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-offset': [0, -2],
        'text-font': ['Roboto Regular'],
        'text-size': 12,
      },
      'paint': {
        'text-halo-color': 'white',
        'text-halo-width': 1,
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('click', id, onClickCallback);

    return () => {
      Array.from(map.getContainer().getElementsByClassName('mapboxgl-popup')).forEach(el => el.remove());

      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('click', id, onClickCallback);

      map.removeLayer(id);
      map.removeSource(id);
    };
  }, [onClickCallback]);

  useEffect(() => {
    proj4.defs([
      [
        "EPSG:3857",
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"],
      [
        "EPSG:3395",
        "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"],
      [
        "EPSG:4326",
        "+proj=longlat +datum=WGS84 +no_defs"]
    ]);

    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: positions.map(position => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          //coordinates: [position.longitude, position.latitude],
          coordinates: proj4(proj4('EPSG:4326'), proj4('EPSG:3395'), [position.longitude, position.latitude]),
        },
        properties: createFeature(devices, position),
      }))
    });
  }, [devices, positions]);

  return null;*/

}

export default PositionsMap;
