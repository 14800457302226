import React, { useState } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@material-ui/core';
import t from '../../common/localization';
import { formatPosition } from '../../common/formatter';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import RoomIcon from '@material-ui/icons/Room';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

const Events = (props) => {

    const { onClick, items } = props;
    const [selectedRow, setSelectedRow] = useState(-1);

    const eventIcon = (event) => {
        switch (event) {
            case 'deviceOnline':
                return <RoomIcon />
            case 'deviceUnknown':
                return <LocationOffIcon />
            case 'deviceOffline':
                return <LocationOffIcon />
            case 'deviceMoving':
                return <SwapCallsIcon />
            case 'deviceStopped':
                return <LocalParkingIcon />
            default:
                break;
        }
    }

    const eventName = (event) => {
        switch (event) {
            case 'deviceOnline':
                return "Включено"
            case 'deviceUnknown':
                return "Неизвестно"
            case 'deviceMoving':
                return "Движение"
            case 'deviceStopped':
                return "Остановка"
            case 'deviceOffline':
                return "Отключено"
            default:
                return event
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{ }</TableCell>
                        <TableCell>{t('reportStartTime')}</TableCell>
                        <TableCell>Событие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={item.id} onClick={() => { console.log(item) }}>
                            <TableCell>{eventIcon(item.type)}</TableCell>
                            <TableCell>{formatPosition(item, 'serverTime')}</TableCell>
                            <TableCell>{eventName(item.type)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Events;
