import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { LinearProgress, isWidthDown, isWidthUp, withWidth, Paper, Typography } from '@material-ui/core';
import ReportContext from './ReportContext';
import Filter from './components/Filter';
import Trips from './tables/Trips';
import { formatDate, formatDistance, formatHours, formatSpeed } from '../common/formatter';
import { useSelector } from 'react-redux';
import Stops from './tables/Stops';
import { useAttributePreference } from '../common/preferences';
import Events from './tables/Events';

const useStyles = makeStyles((theme) => ({
    rootDesktop: {
        flexGrow: 1,
        display: 'flex',
        height: '100%'
    },
    panelsDesktop: {
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    rootMobile: {
        flexGrow: 1,
        flexDirection: 'column-reverse',
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: 'visible',
    },
    tab: {
        minWidth: '48px',
    }
}));

function TabPanel(props) {
    const { children, value, index, width, loading, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={index}
            aria-labelledby={index}
            {...other}
        >
            {loading &&
                <LinearProgress />
            }
            {value === index && (
                <div style={isWidthDown('sm', width) ? { height: '50vh' } : {}}>
                    {children}
                </div>
            )}
        </div>
    );
}

const ReportLayout = ({ width, setCenter, setZoom }) => {
    const classes = useStyles();
    const [tab, setTab] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const { filter, trips, setTrips, groupedItems, setGroupedItems, stops, setStops, setPositions, events, setEvents } = useContext(ReportContext);
    const deviceId = useSelector(state => {
        if (state.devices.selectedId) {
            return state.devices.selectedId;
        }
        return null;
    });
    const distanceUnit = useAttributePreference('distanceUnit');
    const speedUnit = useAttributePreference('speedUnit');

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const handleSendRequest = async (url, setItems) => {
        if (deviceId) {
            setLoading(true);
            let from = filter.from.toISOString();
            let to = filter.to.toISOString();
            let mail = false;

            const query = new URLSearchParams({ deviceId, from, to, mail });
            const response = await fetch(`${url}${query.toString()}`, { Accept: 'application/json' });
            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType) {
                    if (contentType === 'application/json') {
                        setItems(await response.json());
                    } else {
                        window.location.assign(window.URL.createObjectURL(await response.blob()));
                    }
                }
            }
            setLoading(false);
        }
    }

    const handleDownloadReport = () => {
        let csv = '';
        if (tab == 0) {
            csv = `Начальное время;Одометр (км), начало;Конечное время;Одометр (км), окончание;Расстояние (км);Средняя скорость;Максимальная скорость;Длительность\n`
            groupedItems.map(item => {
                csv += `${formatDate(item.startTime)};${new String((item.startOdometer * 0.001).toFixed(2)).replace('.', ',')};${formatDate(item.endTime)};${new String((item.endOdometer * 0.001).toFixed(2)).replace('.', ',')};${new String((item.distance * 0.001).toFixed(2)).replace('.', ',')};${formatSpeed(item.averageSpeed, speedUnit)};${formatSpeed(item.maxSpeed, speedUnit)};${formatHours(item.duration)}\n`
            })
        }
        if (tab == 1) {
            csv = `Начальное время;Конечное время;Длительность;Адрес\n`
            groupedItems.map(item => {
                csv += `${formatDate(item.startTime)};${formatDate(item.endTime)};${formatHours(item.duration)};${item.address}\n`
            })
        }

        const blobUrl = URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),
            csv
        ],
            { type: "text/plain;charset=utf-8" }));
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = 'report.csv';

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        document.body.removeChild(link);
    };

    const handleGetpositions = async (trip) => {
        const deviceId = trip.deviceId;
        const from = (filter.grouped == 'all') ? new Date(Date.parse(filter.from)).toISOString() : new Date(Date.parse(trip.startTime)).toISOString()
        const to = (filter.grouped == 'all') ? new Date(Date.parse(filter.to)).toISOString() : new Date(Date.parse(trip.endTime)).toISOString()
        const type = 'allEvents'

        const query = new URLSearchParams({ deviceId, type, from, to });
        const response = await fetch(`/api/positions?${query.toString()}`, { Accept: 'application/json' });
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType) {
                await setPositions(await response.json())
            }
        }

        return Promise.resolve();
    }

    useEffect(() => {
        handleSendRequest(
            '/api/reports/trips?', 
            setTrips)
        handleSendRequest('/api/reports/stops?', setStops)
        handleSendRequest('/api/reports/events?', setEvents)
    }, [filter.grouped, filter.period, filter.from, filter.to, deviceId])

    useEffect(() => {
        let items = null;
        if (tab == 0) items = trips;
        if (tab == 1) items = stops;
        if (tab == 0) {
            if (filter.grouped == 'trip') setGroupedItems(items);
            if (filter.grouped == 'day') {
                var map = items.reduce((acc, cur) => {
                    let index = formatDate(cur.startTime, 'DD.MM.YYYY');
                    acc[index] = acc[index] || {
                        ...cur,
                        startTime: cur.startTime,
                        startOdometer: cur.startOdometer,
                        endTime: cur.endTime,
                        endOdometer: 0,
                        distance: 0,
                        averageSpeed: cur.averageSpeed,
                        maxSpeed: 0,
                        duration: 0
                    };

                    acc[index].endOdometer = cur.endOdometer;
                    acc[index].endTime = cur.endTime;
                    acc[index].distance += cur.distance;
                    acc[index].averageSpeed = (acc[index].averageSpeed + cur.averageSpeed) / 2;
                    acc[index].duration += cur.duration;
                    acc[index].maxSpeed = (acc[index].maxSpeed > cur.maxSpeed) ? acc[index].maxSpeed : cur.maxSpeed;
                    return acc;
                }, {});
                setGroupedItems(Object.values(map));

            }
            if (filter.grouped == 'all') {
                handleGetpositions({deviceId: deviceId})
                if (items.length > 0) {
                    setGroupedItems([{
                        ...items[0],
                        startTime: items[0].startTime,
                        startOdometer: items[0].startOdometer,
                        endTime: items[items.length - 1].endTime,
                        endOdometer: items[items.length - 1].endOdometer,
                        distance: items.reduce(function (a, b) {
                            return { distance: a.distance + b.distance };
                        }).distance,
                        averageSpeed: items.reduce(function (a, b) {
                            return { averageSpeed: a.averageSpeed + b.averageSpeed };
                        }).averageSpeed / items.length,
                        maxSpeed: items.reduce(function (a, b) {
                            return { maxSpeed: (a.maxSpeed > b.maxSpeed) ? a.maxSpeed : b.maxSpeed };
                        }).maxSpeed,
                        duration: items.reduce(function (a, b) {
                            return { duration: a.duration + b.duration };
                        }).duration
                    }])
                } else setGroupedItems(items)
            };
        }
    }, [trips, stops, filter.grouped, tab])

    return (
        <div className={isWidthUp('sm', width) ? classes.rootDesktop : classes.rootMobile}>
            {isWidthUp('sm', width) &&
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    orientation="vertical"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabs}
                >
                    <Tab icon={<SwapCallsIcon />} label={<Typography style={{ fontSize: '0.5rem' }} variant="caption">поездки</Typography>} id={0} className={classes.tab} />
                    <Tab icon={<LocalParkingIcon />} label={<Typography style={{ fontSize: '0.5rem' }} variant="caption">остановки</Typography>} id={1} className={classes.tab} />
                    <Tab icon={<EventNoteIcon />} label={<Typography style={{ fontSize: '0.5rem' }} variant="caption">события</Typography>} id={2} className={classes.tab} />
                </Tabs>
            }
            {isWidthDown('sm', width) &&
                <Paper variant="outlined" square style={{
                    left: 0,
                    right: 0,
                    position: 'fixed',
                    bottom: '0px'
                }} >
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        orientation="horizontal"
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.tabs}
                        variant="fullWidth"
                    >
                        <Tab icon={<SwapCallsIcon />} label="Поездки" id={0} />
                        <Tab icon={<LocalParkingIcon />} label="Остановки" id={1} />
                        <Tab icon={<EventNoteIcon />} label="События" id={2} />
                    </Tabs>
                </Paper>
            }

            <div style={{ overflow: 'auto', width: '100%' }}>
                <Filter filter={filter} downloadReport={handleDownloadReport} />
                <TabPanel value={tab} index={0} width={width} loading={loading}  >
                    <div style={isWidthDown('sm', width) ? { marginBottom: '72px' } : {}}>
                        <Trips items={groupedItems} onClick={(item) => handleGetpositions(item)} />
                    </div>
                </TabPanel>
                <TabPanel value={tab} index={1} width={width} loading={loading} >
                    <div style={isWidthDown('sm', width) ? { marginBottom: '72px' } : {}}>
                        <Stops items={stops} onClick={(item) => { setCenter([item.longitude, item.latitude]); setZoom(17); }} />
                    </div>
                </TabPanel>
                <TabPanel value={tab} index={2} width={width} loading={loading} >
                    <div style={isWidthDown('sm', width) ? { marginBottom: '72px' } : {}}>
                        <Events items={events} onClick={(item) => { setCenter([item.longitude, item.latitude]); setZoom(17); }} />
                    </div>
                </TabPanel>
            </div>
        </div>
    );
}

export default withWidth()(ReportLayout);