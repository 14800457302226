import React from 'react';
import { Chip, isWidthDown, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, withWidth, Tooltip } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  tableCell: {
    color: 'white',
    fontSize: '1em'
  },
}));

const DeviceTableView = ({ items, width }) => {
  const classes = useStyles();

  const lastUpdate = (time) => {
    let diff = moment().diff(time, 'second');
    if (diff < 60) return "<1м"
    if (diff < 3600) return `${Math.round(diff / 60)}м`
    if (diff < 86400) return `${Math.round(diff / 3600)}ч`
    if (diff < 604800) return `${Math.round(diff / 86400)}д`
    if (diff < 2419200) return `${Math.round(diff / 604800)}н`
    if (diff < 29030400) return `${Math.round(diff / 2419200)}м`
    return `${Math.round(diff / 29030400)}г`
  }

  return (
    <TableContainer style={{ maxWidth: (isWidthDown('xs', width)) ? "85vw" : "35vw" }}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          {items.map((item) => {
            let device = item.get('device');
            return (
              <TableRow key={device.id}>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                  {device.name}
                </TableCell>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                  <Tooltip title={device.lastUpdate ? `${moment(device.lastUpdate).format('D MMM YYYY HH:mm:ss')} (${moment(device.lastUpdate).fromNow()})` : ''}>
                    <Chip
                      label={!!device.lastUpdate ? lastUpdate(moment(device.lastUpdate)) : ''}
                      size='small'
                      style={{
                        backgroundColor: (moment().diff(device.lastUpdate, 'second') < 3600) ? '#1cac00' :
                          (moment().diff(device.lastUpdate, 'second') < 10800) ? '#daa620' : '#aa1313',
                        color: 'white',
                        width: '40px'
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withWidth()(DeviceTableView);
