import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { useContext, useEffect } from 'react';

import { Feature } from 'ol';
import MapContext from './MapContext';
import arrowOffIcon from '../images/arrow-off.svg'
import Style from 'ol/style/Style';
import Stroke from "ol/style/Stroke";
import Icon from 'ol/style/Icon';
import speed from '../images/speed.svg';
import parking from '../images/parking.svg';
import { isWidthUp, withWidth } from '@material-ui/core';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';

const createMarkerFeature = (position, width) => {
  var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));

  var iconStyle = new Style({
    image: new Icon({
      src: arrowOffIcon,
      rotation: position.course * 0.017,
      scale: isWidthUp('sm', width) ? 0.5 : 1.5
    })
  });
  marker.setStyle(iconStyle);
  marker.set('position', position);

  return marker
};


const ReplayPathMap = ({ route, stops, width, setCenter, setZoom }) => {
  const { map, routeSource, routeMarkerSource } = useContext(MapContext);

  const createFeature = (label, position, type) => {
    var marker = new Feature(new Point(fromLonLat([position.longitude, position.latitude])));
    var iconStyle = new Style({
      image: new Icon({
        src: (type == 'speed') ? speed : parking,
        scale: 1,
        anchor: [0.5, 1],
      }),
      text: new Text({
        text: label ? label : '',
        scale: 1.5,
        offsetY: -45,
        overflow: true,
        backgroundFill: new Fill({
          color: [200, 200, 200, 0.6],
        })
      })
    });
    marker.setStyle(iconStyle);

    return marker
  };

  const getColorGrad = (x1, x2, step, stepCount) => {
    return x1 + (x2 - x1) * step / stepCount;
  }

  const getColor = (prev, cur) => {
    if (prev < 60 && cur < 60)
      return [0, getColorGrad(0, 255, (prev + cur) / 2, 60), 0, 1];

    if (prev < 90 && cur < 90)
      return [getColorGrad(0, 255, (prev + cur) / 2, 90), getColorGrad(0, 255, (prev + cur) / 2, 90), 0, 1];

    return [255, 0, 0, 1];
  }

  useEffect(() => {
    routeSource.clear();
    routeMarkerSource.clear();
    if (route && route.length > 0) {
      var MarkerFeatures = []
      let prev = null;

      route.map(position => {
        if (!!prev) {
          let ls = new Feature(new LineString([
            fromLonLat([prev.longitude, prev.latitude]),
            fromLonLat([position.longitude, position.latitude]),
          ]));

          ls.setStyle(new Style({
            stroke: new Stroke({
              color: getColor(prev.speed * 1.852, position.speed * 1.852),
              width: 5,
            }),
          }));
          routeSource.addFeatures([ls]);

          /*if (isAddSpeedMarkerPrev(prev.speed * 1.852, position.speed * 1.852))
            routeSource.addFeatures([createFeature(formatSpeed(position.speed, 'kmh'), prev, 'speed')]);
          if (isAddSpeedMarkerCur(prev.speed * 1.852, position.speed * 1.852))
            routeSource.addFeatures([createFeature(formatSpeed(position.speed, 'kmh'), position, 'speed')]);*/

          prev = position;
        } else {
          prev = position;
        }
        MarkerFeatures.push(createMarkerFeature(position, width));
      });

      routeMarkerSource.addFeatures(MarkerFeatures);
      setCenter([route[0].longitude, route[0].latitude]);
      setZoom(17);
    }

  }, [route]);

  /*useEffect(() => {
    routeSource.clear();
    if (stops && stops.length > 0) {
      stops.map(position => {
        routeSource.addFeatures([createFeature(`${formatDate(position.startTime)} - ${formatDate(position.endTime)} (${formatHours(position.duration)})`, position, 'stops')]);
      });
    }
  }, [stops]);*/

  return null;

}

export default withWidth()(ReplayPathMap);
