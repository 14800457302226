import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from './store';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/Map';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TimelineIcon from '@material-ui/icons/Timeline';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FolderIcon from '@material-ui/icons/Folder';
import ReplayIcon from '@material-ui/icons/Replay';
import t from './common/localization';
import logo from './images/logo.png'

const useStyles = makeStyles(theme => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  list: {
    width: 250
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    maxHeight: '3em'
  }
}));

const MainToolbar = () => {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);
  const userId = useSelector(state => state.session.user && state.session.user.id);

  const openDrawer = () => { setDrawer(true) }
  const closeDrawer = () => { setDrawer(false) }

  const handleLogout = async () => {
    const response = await fetch('/api/session', { method: 'DELETE' });
    if (response.ok) {
      dispatch(sessionActions.updateUser(null));
      history.push('/login');
    }
  }
  /*
    const handleSave = () => {
      let url = `/api/devices`;
      let items = [{"name":"ЯКТ_Нива_2131  К525СХ", "uniqueId":"862531043193383", "phone":"89097335795", "groupId":0, "category":"car"}, 
      {"name":"МО_Нива_2131 М446КЕ", "uniqueId":"862531043780213", "phone":"89097335833", "groupId":0, "category":"car"}, 
      {"name":"ЯКТ_Нива_2131 М165АХ", "uniqueId":"862531043738211", "phone":"89097335890", "groupId":0, "category":"car"}, 
      {"name":"ЯКТ_Газель В098МК", "uniqueId":"862531043196550", "phone":"89097335935", "groupId":0, "category":"car"}, 
      {"name":"ЯКТ_Газель М576ХЕ", "uniqueId":"862531043261420", "phone":"89097336064", "groupId":0, "category":"car"}, 
      {"name":"ЯКТ_Газель М975НХ", "uniqueId":"862531043855791", "phone":"89097336420", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Largus", "uniqueId":"862531043843219", "phone":"89097336500", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Largus Baza", "uniqueId":"862531043870162", "phone":"89097336734", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Toyota_Hilux", "uniqueId":"862531043823070", "phone":"89097336740", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Газель", "uniqueId":"862531043253682", "phone":"89097336765", "groupId":0, "category":"car"}, 
      {"name":"КАР_Нива_2131 В007СН", "uniqueId":"862531043858035", "phone":"89097336801", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Нива_2131 Е077СХ", "uniqueId":"862531043898221", "phone":"89097336839", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Нива_2131  К604АМ", "uniqueId":"862531043887596", "phone":"89097336896", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Нива_2131 БС М252АК", "uniqueId":"862531043911362", "phone":"89097336908", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_Нива_2131 М340КЕ", "uniqueId":"862531043263863", "phone":"89097336913", "groupId":0, "category":"car"}, 
      {"name":"КУД_Нива_2131 Н373КЕ", "uniqueId":"862531043900548", "phone":"89097336977", "groupId":0, "category":"car"}, 
      {"name":"КУД_Нива_2131 К274МА", "uniqueId":"862531043681296", "phone":"89097336982", "groupId":0, "category":"car"}, 
      {"name":"КУД_Нива_2131 К465ВА", "uniqueId":"862531043651471", "phone":"89097336987", "groupId":0, "category":"car"}, 
      {"name":"МО_Экскаватор CASE", "uniqueId":"862531043681999",  "groupId":0, "category":"car"}, 
      {"name":"MO_Камаз-Лаборатория", "uniqueId":"862531043633982", "groupId":0, "category":"car"}, 
      {"name":"МО_Toyota_Hilux", "uniqueId":"862531043681189", "groupId":0, "category":"car"}, 
      {"name":"МО_Бульдозер 1 Чирукин", "uniqueId":"862531043599407", "groupId":0, "category":"car"}, 
      {"name":"МО_Бульдозер 2 Чирукин", "uniqueId":"862531045761591", "groupId":0, "category":"car"}, 
      {"name":"МО_Бульдозер CASE", "uniqueId":"862531045980217", "groupId":0, "category":"car"}, 
      {"name":"ПРМ JCB Экскаватор", "uniqueId":"862531045724144", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_1 Колонна Укладчик", "uniqueId":"862531043168880", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_1 Колонна Рыхлитель", "uniqueId":"862531043215418", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_1 Колонна Хозяйка", "uniqueId":"862531043082362", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_2 Колонна Укладчик", "uniqueId":"862531043205914", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_2 Колонна Рыхлитель", "uniqueId":"862531043236760", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_2 Колонна Укладчик", "uniqueId":"862531043198838", "groupId":0, "category":"car"}, 
      {"name":"ПРМ_КранБорт", "uniqueId":"862531043252783", "groupId":0, "category":"car"}]
  
      items.map(item => {
      const response = fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });
      console.log(response)
    })
  
    };
  
    handleSave()*/
  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant='dense'>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
          <div className={classes.flex}>
            <img src={logo} className={classes.logo} />
          </div>
          <Button color="inherit" onClick={handleLogout}>{t('loginLogout')}</Button>
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={closeDrawer}>
        <div
          tabIndex={0}
          className={classes.list}
          role="button"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}>
          <List>
            <ListItem button onClick={() => history.push('/')}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary={t('mapTitle')} />
            </ListItem>
            {/*<ListItem button onClick={() => history.push('/replay')}>
              <ListItemIcon>
                <ReplayIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportReplay')} />
            </ListItem>
  */}
          </List>
          <Divider />
          {/*<List
            subheader={
              <ListSubheader>
                {t('reportTitle')}
              </ListSubheader>
            }>
            <ListItem button onClick={() => history.push('/reports/route')}>
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportRoute')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/event')}>
              <ListItemIcon>
                <NotificationsActiveIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportEvents')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/trip')}>
              <ListItemIcon>
                <PlayCircleFilledIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportTrips')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/stop')}>
              <ListItemIcon>
                <PauseCircleFilledIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportStops')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/summary')}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportSummary')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/reports/chart')}>
              <ListItemIcon>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary={t('reportChart')} />
            </ListItem>
          </List>
          <Divider />
          */}
          <List
            subheader={
              <ListSubheader>
                {t('settingsTitle')}
              </ListSubheader>
            }>
            <ListItem button disabled={!userId} onClick={() => history.push(`/user/${userId}`)}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={t('settingsUser')} />
            </ListItem>
            {adminEnabled &&
              <>
                <ListItem button onClick={() => history.push('/settings/notifications')}>
                  <ListItemIcon>
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sharedNotifications')} />
                </ListItem>
                <ListItem button onClick={() => history.push('/settings/groups')}>
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settingsGroups')} />
                </ListItem>
                <ListItem button onClick={() => history.push('/settings/drivers')}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sharedDrivers')} />
                </ListItem>
                <ListItem button onClick={() => history.push('/settings/attributes')}>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sharedComputedAttributes')} />
                </ListItem>
              </>
            }
          </List>
          {adminEnabled && (
            <>
              <Divider />
              <List
                subheader={
                  <ListSubheader>
                    {t('userAdmin')}
                  </ListSubheader>
                }>
                <ListItem button onClick={() => history.push('/admin/server')}>
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settingsServer')} />
                </ListItem>
                <ListItem button onClick={() => history.push('/admin/users')}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settingsUsers')} />
                </ListItem>
              </List>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default MainToolbar;
