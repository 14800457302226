import t from '../common/localization'
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate, formatPosition } from '../common/formatter';
import { makeStyles, SvgIcon, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { fromLonLat } from 'ol/proj';
import SpeedIcon from '@material-ui/icons/Speed';
import { ReactComponent as DateIcon } from '../images/date-icon.svg';
import { ReactComponent as MapIcon } from '../images/map-address-icon.svg';

const useStyles = makeStyles(theme => ({
  tableCell: {
    color: 'white',
  },
}));

const StatusView = ({ deviceId, onShowDetails }) => {
  const device = useSelector(state => state.devices.items[deviceId]);
  const position = useSelector(state => state.positions.items[deviceId]);

  const classes = useStyles();

  return (
    <Table size="small">
      <TableBody>
        {/*Object.keys(position.attributes).map(attribute =>
          <TableRow>
            <TableCell className={classes.tableCell}>
              {attribute}
            </TableCell>
            <TableCell className={classes.tableCell}>{position.attributes[attribute]}</TableCell>
          </TableRow>
        )*/}

        <TableRow>
          <TableCell className={classes.tableCell}>
            <SvgIcon>
              <DateIcon />
            </SvgIcon>
          </TableCell>
          <TableCell className={classes.tableCell}>{formatDate(device.lastUpdate)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell}><SpeedIcon /></TableCell>
          <TableCell className={classes.tableCell}>{formatPosition(position.speed, 'speed')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <SvgIcon>
              <MapIcon />
            </SvgIcon>
          </TableCell>
          <TableCell className={classes.tableCell}>{position.address}</TableCell>
        </TableRow>
        {position.attributes.batteryLevel &&
          <TableRow>
            <TableCell className={classes.tableCell}>{t('positionBattery')}</TableCell>
            <TableCell className={classes.tableCell}>{formatPosition(position.attributes.batteryLevel, 'batteryLevel')}</TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
};

export default StatusView;
