import React, { useRef, useState, useEffect } from "react"
import MapContext from "./MapContext";
import * as ol from "ol";
import { register } from 'ol/proj/proj4';
import { get } from 'ol/proj';
import proj4 from 'proj4';
import TileLayer from "ol/layer/Tile";
import ReactDOM from 'react-dom';
import "ol/ol.css";
import "./Map.css";
import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import { Paper } from "@material-ui/core";
import { Provider } from "react-redux";
import store from '../store';
import StatusView from "./StatusView";
import { Control, defaults as defaultControls } from 'ol/control';
import OSM from 'ol/source/OSM';
import LayersIcon from '@material-ui/icons/Layers';
import { Circle, Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';

import PathMarkerView from "./PathMarkerView";

import './Map.css'
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LayerGroup from 'ol/layer/Group';
import Point from 'ol/geom/Point';

import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import { renderToString } from 'react-dom/server'
import DeviceTableView from "./DeviceTableView";

var projection;
proj4.defs('BD-MC', '+proj=merc +lon_0=0 +units=m +ellps=clrk66 +no_defs');
proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
register(proj4);
projection = get('EPSG:3395');
if (projection) {
  projection.setExtent([-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244]);
}

const MapView = ({ children, zoom, center, changeSizeMap }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [view, setView] = useState(new ol.View({
    center: center,
    zoom: zoom,
    enableRotation: false
  }));
  const [geolocation, setGeolocation] = useState(new ol.Geolocation({
    trackingOptions: {
      enableHighAccuracy: true,
    },
    projection: view.getProjection(),
  }));
  const [markerSource, setMarkerSource] = useState(new VectorSource({ features: [], }));
  const [markerCluster, setMarkerCluster] = useState(new ClusterSource({
    //distance: 10,
    source: markerSource
  }));
  const [routeSource, setRouteSource] = useState(new VectorSource({ features: [], }));
  const [routeMarkerSource, setRouteMarkerSource] = useState(new VectorSource({ features: [], }));
  const [speedMarkerSource, setSpeedMarkerSource] = useState(new VectorSource({ features: [], }));
  const [stopsMarkerSource, setStopsMarkerSource] = useState(new VectorSource({ features: [], }));
  const [routeMarkerCluster, setRouteMarkerCluster] = useState(new ClusterSource({
    //distance: 10,
    source: routeMarkerSource
  }));
  const [sourceLayer, setSourceLayer] = useState(new LayerGroup({
    layers: [new TileLayer({
      source: new XYZ({
        url: 'https://sat0{1-4}.maps.yandex.net/tiles?l=skl&x={x}&y={y}&z={z}',
        projection: 'EPSG:3395'
      })
    }),
    new TileLayer({
      source: new XYZ({
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&x={x}&y={y}&z={z}',
        projection: 'EPSG:3395'
      })
    })
    ]
  }))
  const [openSwitchLayer, setOpenSwitchLayer] = useState(false);

  const [accuracyFeature, setAccuracyFeature] = useState(new ol.Feature());
  const [positionFeature, setPositionFeature] = useState(new ol.Feature());
  const [pos, setPos] = useState([0, 0])
  const [geolocateLayer, setGeolocateLayer] = useState(new VectorLayer({
    source: new VectorSource({
      features: [accuracyFeature, positionFeature],
    }),
  }));

  const SwitcherControl = (function (Control) {
    function SwitcherControl(opt_options) {
      var options = opt_options || {};

      /*      var button = document.createElement('button');
            button.innerHTML = renderToString(<Tooltip title='Выбор карты'><LayersIcon /></Tooltip>);
      */
      var button = renderToString(<button title='Выбор карты'><LayersIcon /></button>);

      var element = document.createElement('div');
      element.className = 'ol-unselectable ol-control ol-control-switcher';
      //element.appendChild(button);
      element.innerHTML = button;

      Control.call(this, {
        element: element,
        target: options.target,
      });

      element.addEventListener('click', this.handleSwitchSource.bind(this), false);
    }

    if (Control) SwitcherControl.__proto__ = Control;
    SwitcherControl.prototype = Object.create(Control && Control.prototype);
    SwitcherControl.prototype.constructor = SwitcherControl;

    SwitcherControl.prototype.handleSwitchSource = function handleSwitchSource() {
      setOpenSwitchLayer(true)
    };

    return SwitcherControl;
  }(Control));

  const GeolocateControl = (function (Control) {
    function GeolocateControl(opt_options) {
      var options = opt_options || {};

      /*      var button = document.createElement('button');
            button.innerHTML = renderToString(<Tooltip title='Выбор карты'><LayersIcon /></Tooltip>);
      */
      var button = renderToString(<button title='Показать текущую позицию'><GpsNotFixedIcon /></button>)
      var element = document.createElement('div');
      element.className = 'ol-unselectable ol-control ol-geolocation';
      //element.appendChild(button);
      element.innerHTML = button;

      Control.call(this, {
        element: element,
        target: options.target,
      });

      element.addEventListener('click', this.handleClick.bind(this), false);
    }

    if (Control) GeolocateControl.__proto__ = Control;
    GeolocateControl.prototype = Object.create(Control && Control.prototype);
    GeolocateControl.prototype.constructor = GeolocateControl;

    GeolocateControl.prototype.handleClick = function handleClick() {
      if (geolocateLayer.getVisible()) {
        geolocateLayer.setVisible(false);
        this.element.innerHTML = renderToString(<button title='Показать текущую позицию'><GpsNotFixedIcon /></button>);
      } else {
        geolocateLayer.setVisible(true);
        geolocation.setTracking(true);
        let coordinates = geolocation.getPosition();
        if (coordinates) {
          this.getMap().getView().setCenter(coordinates)
          this.getMap().getView().setZoom(17);
          this.element.innerHTML = renderToString(<button title='Скрыть текущую позицию'> <GpsFixedIcon /> </button>);
        }
      }
    };

    return GeolocateControl;
  }(Control));

  // on component mount
  useEffect(() => {
    let options = {
      controls: defaultControls().extend([new SwitcherControl(), new GeolocateControl()]),
      layers: [
        sourceLayer,
        new VectorLayer({
          style: function (feature) {
            return feature.get('style');
          },
          source: speedMarkerSource
        }),
        new VectorLayer({
          style: function (feature) {
            return feature.get('style');
          },
          source: stopsMarkerSource
        }),
        /*new VectorLayer({
          style: function (feature) {
            return feature.get('style');
          },
          source: markerSource
        }),*/
        new VectorLayer({
          style:
            function (feature, resolution) {
              //return feature.get('features')[0].getStyle();
              var size = feature.get('features').length;
              if (size == 1) return feature.get('features')[0].getStyle();
              else {
                var style = new Style({
                  image: new Circle({
                    radius: Math.min(size * 2 + 5, 20),
                    stroke: new Stroke({
                      color: 'white'
                    }),
                    fill: new Fill({
                      color: '#00bcd4'
                    })
                  }),
                  text: new Text({
                    scale: 2,
                    text: size.toString(),
                    fill: new Fill({
                      color: 'white'
                    })
                  })
                })
                return style;
              }
            },
          source: markerCluster
        }),
        new VectorLayer({
          source: routeSource
        }),
        new VectorLayer({
          style:
            function (feature) {
              return feature.get('features')[0].getStyle();
            },
          source: routeMarkerCluster
        }),
        geolocateLayer
      ],
      view: view
    };

    let mapObject = new ol.Map(options);
    mapObject.on('pointermove', function (evt) { //pointermove click
      var feature = mapObject.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      if (feature) {
        mapObject.getOverlays().clear()
        var placeholder = document.createElement('div');

        var popup = new ol.Overlay({
          element: placeholder,
          positioning: 'center-right'
          //positioning: 'bottom-center'
        });
        popup.setPosition(evt.coordinate);
        mapObject.addOverlay(popup);

        //var position = feature.get('position')
        var features = feature.get('features')
        var device = (features && features[0] && features.length == 1) ? features[0] : null;

        if (!Array.isArray(features)) features = [features]
        if (!!device || !!features)
          ReactDOM.render(
            <Paper style={{ backgroundColor: "rgba(33,33,33,0.7)" }}>
              <Provider store={store}>
                {!!device && device.get('device') && <StatusView deviceId={device.get('device').id} />}
                {!!features[0] && !!(features[0].get('position')) && <PathMarkerView item={features[0].get('position')} />}
                {!!features[0] && !!(features[0].get('device') && features.length > 1) && <DeviceTableView items={features} />}
              </Provider>
            </Paper>
            ,
            placeholder
          );

        //document.getElementById('popup').innerHTML = feature.get('device').name
      } else mapObject.getOverlays().clear()//document.getElementById('popup').innerHTML = "<div id='popup' />"
    });

    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    geolocation.on('change:accuracyGeometry', function () {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });

    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    geolocation.on('change:position', function () {
      const coordinates = geolocation.getPosition();
      positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      setPos(coordinates)
    });

    /*const locate = document.createElement('div');
    locate.className = 'ol-control ol-geolocation';
    locate.innerHTML = renderToString(<button title='Показать текущую позицию'>  <GpsNotFixedIcon /> </button>);

    locate.addEventListener('click', function () {
      
    });

    mapObject.addControl(new Control({
      element: locate
    }));
    */

    const coordinates = geolocation.getPosition();
    coordinates && setPos(coordinates)

    return () => mapObject.setTarget(undefined);
  }, [sourceLayer]);

  useEffect(() => {
    if (!map) return;
    map.setTarget(mapRef.current)
  }, [mapRef.current])

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center)

  }, [center])

  // change size
  useEffect(() => {
    if (!map) return;

    map.updateSize()

  }, [changeSizeMap])

  if (!!map) map.updateSize();
  return (
    <MapContext.Provider value={{
      map: map,
      routeSource: routeSource,
      markerSource: markerSource,
      speedMarkerSource: speedMarkerSource,
      stopsMarkerSource: stopsMarkerSource,
      routeMarkerSource: routeMarkerSource,
      routeMarkerCluster: routeMarkerCluster
    }} >
      <div ref={mapRef} className="ol-map" >
        <div id='popup' />
        {children}
      </div>

      <Dialog onClose={() => setOpenSwitchLayer(false)} open={openSwitchLayer}>
        <DialogTitle id="SwitchLayer-dialog-title">Карта</DialogTitle>
        <List>
          <ListItem button onClick={() => {
            setSourceLayer(new TileLayer({
              source: new OSM(),
              projection: 'EPSG:3395'
            })
            );
            setOpenSwitchLayer(false)
          }} key={'OSM'}>
            <ListItemAvatar>
              <Avatar>
                <LayersIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'OpenStreetMap'} />
          </ListItem>
          <ListItem button onClick={() => {
            setSourceLayer(new LayerGroup({
              layers: [new TileLayer({
                source: new XYZ({
                  url: 'https://sat0{1-4}.maps.yandex.net/tiles?l=skl&x={x}&y={y}&z={z}',
                  projection: 'EPSG:3395'
                })
              }),
              new TileLayer({
                source: new XYZ({
                  url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&x={x}&y={y}&z={z}',
                  projection: 'EPSG:3395'
                })
              })
              ]
            })
            );
            setOpenSwitchLayer(false)
          }} key={'Yandex'}>
            <ListItemAvatar>
              <Avatar>
                <LayersIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Yandex'} />
          </ListItem>

        </List>
      </Dialog>
    </MapContext.Provider>
  )
}

export default MapView;