import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper, makeStyles, Fab } from '@material-ui/core';
import t from '../../common/localization';
import { formatDistance, formatSpeed, formatHours, formatDate } from '../../common/formatter';
import { useAttributePreference } from '../../common/preferences';
import { useSelector } from 'react-redux';

const Trips = ({ onClick, items }) => {

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const [selectedRow, setSelectedRow] = useState(-1);
  
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead >
          <TableRow>
            <TableCell>{t('reportStartTime')}</TableCell>
            <TableCell>{t('reportStartOdometer')}</TableCell>
            <TableCell>{t('reportEndTime')}</TableCell>
            <TableCell>{t('reportEndOdometer')}</TableCell>
            <TableCell>{t('sharedDistance')}</TableCell>
            <TableCell>{t('reportAverageSpeed')}</TableCell>
            <TableCell>{t('reportMaximumSpeed')}</TableCell>
            <TableCell>{t('reportDuration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { items && items.map((item, index) => (
            <TableRow key={index} onClick={() => { onClick(item); setSelectedRow(index) }} style={{ backgroundColor: (index === selectedRow) ? "lightgray" : "" }}>
              <TableCell>{formatDate(item.startTime)}</TableCell>
              <TableCell>{formatDistance(item.startOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDate(item.endTime)}</TableCell>
              <TableCell>{formatDistance(item.endOdometer, distanceUnit)}</TableCell>
              <TableCell>{formatDistance(item.distance, distanceUnit)}</TableCell>
              <TableCell>{formatSpeed(item.averageSpeed, speedUnit)}</TableCell>
              <TableCell>{formatSpeed(item.maxSpeed, speedUnit)}</TableCell>
              <TableCell>{formatHours(item.duration)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Trips;
