import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogTitle, FormControlLabel, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function FilterDialog(props) {
    const classes = useStyles();
    const { onClose, filter, setFilter, open } = props;

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Параметры</DialogTitle>
            <FormControlLabel
                control={
                    <Switch
                        checked={filter.showOffline}
                        onChange={() => setFilter({ ...filter, showOffline: !filter.showOffline })}
                        name="showOffline"
                        color="primary"
                    />
                }
                label="Отображать устройства Offline"
            />
        </Dialog>
    );
}

export default function DeviceFilterView({ filter, setFilter }) {
    const classes = useStyles();
    const [search, setSearch] = useState();
    const [open, setOpen] = useState(false);

    return (
        <Paper className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="menu" onClick={() => setOpen(true)}>
                <MenuIcon />
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder="Поиск..."
                fullWidth
                onChange={(event) => { setFilter({ ...filter, text: event.target.value }); }}
                defaultValue={filter.text}
                value={filter.text}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={() => { setSearch(''); setFilter({ ...filter, text: '' }) } }>
                <CloseIcon />
            </IconButton>
            <FilterDialog open={open} onClose={() => setOpen(false)} filter={filter} setFilter={setFilter} />
        </Paper>

    );
}