import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { devicesActions } from './store';
import EditCollectionView from './EditCollectionView';
import { useEffectAsync } from './reactHelper';
import { Box, Chip, ListSubheader, Tooltip } from '@material-ui/core';
import DeviceFilterView from './DevicesFilter';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: 'calc(100% - 96px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '50vh',
    }
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  deviceName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between'
  },
  deviceNameLabel: {
    width: 'calc(100% - 40px);',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}));

const filtring = (devicses, filter = null) => {
  let rezult = (filter && filter.text) ?
    devicses.filter(device => device.name.toLowerCase().includes(filter.text.toLowerCase())) : devicses

  if (filter && !filter.showOffline)
    rezult = rezult.filter(device => device.status == 'online')

  rezult = rezult.reduce(function (r, a) {
    r[a.groupId] = r[a.groupId] || [];
    r[a.groupId].push(a);
    return r;
  }, Object.create(null));

  Object.keys(rezult).map(group =>
    rezult[group] = rezult[group].sort((a, b) => {
      return a.status !== 'online';
    })
  )
  return rezult
}

const getFilter = () => {
  var filter = JSON.parse(localStorage.getItem('deviceFilter'));
  return (!!filter) ? filter : {
    text: null,
    showOffline: true
  }
}

const DeviceView = ({ updateTimestamp, onMenuClick, onItemClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [groups, setGroups] = useState([]);
  const [filter, setFilter] = useState(getFilter());

  const isAdmin = useSelector(state => state.session.user && state.session.user.administrator);

  useEffect(() => {
    localStorage.setItem('deviceFilter', JSON.stringify(filter));
  }, [filter])

  const items = useSelector(state => Object.values(state.devices.items));
  const positions = useSelector(state => state.positions.items);
  const selectedID = useSelector(state => state.devices.selectedId);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  useEffectAsync(async () => {
    const response = await fetch('/api/groups');
    if (response.ok) {
      let obj = {};
      let res = await response.json()
      res.map(item => obj[item.id] = item)
      setGroups(obj);
    }
  }, [updateTimestamp]);

  const lastUpdate = (time) => {
    let diff = moment().diff(time, 'second');
    if (diff < 60) return "<1м"
    if (diff < 3600) return `${Math.round(diff / 60)}м`
    if (diff < 86400) return `${Math.round(diff / 3600)}ч`
    if (diff < 604800) return `${Math.round(diff / 86400)}д`
    if (diff < 2419200) return `${Math.round(diff / 604800)}н`
    if (diff < 29030400) return `${Math.round(diff / 2419200)}м`
    return `${Math.round(diff / 29030400)}г`
  }

  let filtred = filtring(items, filter)

  return (

    <>
      <DeviceFilterView filter={filter} setFilter={setFilter} />
      <List className={classes.list} dense>
        {Object.keys(filtred).map(groupId =>
          <>
            <ListSubheader disableSticky>{(groups && groups[groupId]) ? groups[groupId].name : ''}</ListSubheader>
            {filtred[groupId].map((item, index, list) => (
              <Fragment key={item.id}>
                <ListItem className={classes.listItem} button key={item.id}
                  onClick={() => {
                    dispatch(devicesActions.select(item));
                    !!positions[item.id] && onItemClick(positions[item.id].longitude, positions[item.id].latitude, 17)
                  }}
                  selected={selectedID == item.id}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: (item.status == 'online') ? "rgb(28, 172, 0)" : "#aa1313" }}>
                      <img className={classes.icon} src={`images/icon/${item.category || 'default'}.svg`} alt="" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div className={classes.deviceName}>
                        <div className={classes.deviceNameLabel}>
                          {item.name}
                        </div>
                        <Tooltip title={item.lastUpdate ? `${moment(item.lastUpdate).format('D MMM YYYY HH:mm:ss')} (${moment(item.lastUpdate).fromNow()})` : ''}>
                          <Chip
                            label={!!item.lastUpdate ? lastUpdate(moment(item.lastUpdate)) : ''}
                            size='small'
                            style={{
                              backgroundColor: (moment().diff(item.lastUpdate, 'second') < 3600) ? '#1cac00' :
                                (moment().diff(item.lastUpdate, 'second') < 10800) ? '#daa620' : '#aa1313',
                              color: 'white',
                              width: '40px'
                            }}
                          />
                        </Tooltip>
                      </div>
                    }
                    secondary={
                      <Tooltip title={(positions[item.id]) ? positions[item.id].address : ''}>
                        <Box component="div" textOverflow="ellipsis" overflow="hidden" className={classes.listItem} whiteSpace="nowrap">
                          {(positions[item.id]) ? positions[item.id].address : ''}
                        </Box>
                      </Tooltip>
                    }
                  />
                  {isAdmin &&
                    <ListItemSecondaryAction>

                      <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                </ListItem>
                {index < list.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </>
        )}
      </List>
    </>
  );
}

const DevicesList = ({ onSelect }) => {
  return (
    <EditCollectionView content={DeviceView} editPath="/device" endpoint="devices" onItemClick={onSelect} />
  );
}

export default DevicesList;
